<template>
  <b-card class="mx-auto mt-2">

    <b-row class="mx-auto mt-2">
      <vue-good-table
        mode="remote"
        :pagination-options="{
          enabled: true,
          perPage: serverParams.perPage,
          perPageDropdown: [10, 20, 50, 100],
          dropdownAllowAll: false,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :is-loading.sync="isLoading"
        :total-rows="totalRecords"
        :rows="resources"
        :columns="columnDefs"

        style-class="vgt-table condensed bordered w-100"
        compact-mode
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-selected-rows-change="onSelectionChange"
      >

        <template
          slot="table-row"
          slot-scope="props"
        >

          <span v-if="props.column.field === 'label'">
            <b-badge
              v-for="label in props.row.label"
              :key="label.key"
              pill
              class="h-6"
              variant="warning"
            >
              {{ label }}
            </b-badge>
          </span>

          <span v-else-if="props.column.field === 'banner_image'">
            <CellRendererImage :params="{banner_image: props.row.banner_image}" />
          </span>

          <span v-else-if="props.column.field === 'actions'">
            <b-button
              v-b-tooltip.hover.top="`Click here to view a perform more actions .`"
              class="btn-icon grid-btn float-left"
              variant="outline-dark"
              @click="performAction(props.row.tag)"
            >
              <feather-icon
                icon="EyeIcon"
                svg-classes="hover:text-primary stroke-current"
              />
            </b-button>
          </span>

        </template>

      </vue-good-table>

    </b-row>
    <b-row>
      <b-modal
        v-model="activateRiasec"
        centered
        hide-footer
        class=""
        title="Send Riasec Assessment"
      >
        <b-row class="mx-1">
          <h6>Select the users who you want to send the assessment to below:</h6>
        </b-row>
        <b-row class="my-1 mx-1">
          <b-input-group class="input-group-merge">
            <b-form-input
              placeholder="Search user by email address or fullname"
              class="search-user"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-row>

        <b-row class="float-left ml-2">
          <b-card
            border-variant="primary"
            bg-variant="transparent"
            class="shadow-none p-0 d-flex "
          >
            <b-form-checkbox
              name="app_boost_gurantee"
              class="mr-2"
              value="application"
            >
              <span>Select all users</span>

            </b-form-checkbox>
          </b-card>
        </b-row>

        <b-row
          v-for="(item, index) in userData"
          :key="index"
          class="float-left ml-2"
        >
          <b-card
            class="mb-1 d-flex w-100"
          >
            <b-form-checkbox
              name="app_boost_gurantee"
              class="mr-2"
              value="application"
            >
              <span>Mark Serin - </span>
              <span>(mark@edu.co.sg) - </span>
              <span class="font-weight-bold">27812122</span>

            </b-form-checkbox>
          </b-card>
        </b-row>
        <b-button
          class="float-right mr-1"
          variant="primary"
        >Send Assessment Links</b-button>
      </b-modal>
    </b-row>
    <ProfileCompletionForm
      :activate-popup="popupProfileQuestions"
      :hide-header="true"
      @open-completion-form="activateTypeForm"
    />
  </b-card>
</template>

<script>

import Ripple from 'vue-ripple-directive'
import { completionMixins } from '@/components/mixins/CompletionMixins'
import { VueGoodTable } from 'vue-good-table'
import CellRendererImage from '../EventManager/cell-renderer/CellRendererImage.vue'
import CellRendererCategory from '../EventManager/cell-renderer/CellRendererCategory.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    VueGoodTable,
    CellRendererImage,
    CellRendererCategory,
  },

  mixins: [completionMixins],
  data() {
    return {
      isLoading: false,
      totalRecords: 0,
      resources: [],

      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          label: 'Title',
          field: 'title',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
          // pinned: true,
        },
        {
          label: 'Banner',
          field: 'banner_image',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Label',
          field: 'label',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },

        {
          label: 'Views',
          field: 'views',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },

        {
          label: 'Rating',
          field: 'rating',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },

        {
          label: 'Type',
          field: 'type',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Created On',
          field: 'created_on',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },
        // {
        //   label: 'Deactivated At',
        //   field: 'deactivated_at',
        //   filter: true,
        //   width: 150,
        // },
        {
          label: 'Actions',
          field: 'actions',
          thClass: 'text-right',
          tdClass: 'text-right',
          cellRendererFramework: 'CellRendererActions',
        },
      ],
      serverParams: {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 10,
      },
      activateRiasec: true,
      exportingCSVIndex: null,
      exportingCSV: false,
      selectedRows: null,
      mqShallShowLeftSidebar: false,
      userData: [1],
    }
  },
  async created() {
    // this.resources = [
    //   {
    //     name: 'User Manual',
    //     by: 'HeadHunters HQ',
    //     email: 'career@headhuntershq.com',
    //     description: '',
    //     image: await this.$generateDownloadUrl('media/black_hq_logo.png'),
    //     id: '1',
    //   },
    //   // {
    //   //   name: 'Interview Training Video', by: 'Alvigor', description: '', image: `${this.$http.defaults.baseURL}/api/media/employer_training.jpg`, id: '2',
    //   // },
    //   {
    //     name: 'Interview Training Video',
    //     by: 'Alvigor',
    //     email: 'jobsecurity@e2i.com.sg',
    //     description: '',
    //     image: await this.$generateDownloadUrl('media/applicant_training.jpg'),
    //     id: '3',
    //   },
    // ]
  },
  mounted() {
    const incompleteProfile = this.checkProfileCompletion()

    // this.$generatePresignedUrl('event-images/uuid-filename.jpg')
    //   .then(response => {
    // })

    if (incompleteProfile) {
      this.popupProfileQuestions = true
    }

    this.initComponent()
  },
  methods: {
    performAction(tag) {
      if (tag === 'riasec') {
        this.activateRiasec = true
      }
    },
    getRecords() {
      const query = `?params=${JSON.stringify(this.serverParams)}`
      this.$http
        .get(`/api/resources${query}`)
        .then(response => {
          console.log(response)
          const { success } = response.data
          if (success) {
            this.resources = response.data.all_resources
            this.totalRecords = response.data.total_records
            this.isLoading = false
          }
        })
        .catch(error => {
          this.isLoading = false
        })
    },
    async initComponent() {
      const { account_type } = this.$store.state.auth.ActiveUser
      if (account_type === this.$AccountTypes.APPLICANT) {
        this.resources = [
          {
            url: '/resources/user-guide',
            name: 'User Manual',
            by: 'HeadHunters HQ',
            description: '',
            image: await this.$generatePresignedUrl('media/black_hq_logo.png'),
            id: '1',
          },
          // {
          //   name: 'Interview Training Video', by: 'Alvigor', description: '', image: `${this.$http.defaults.baseURL}/api/media/employer_training.jpg`, id: '2',
          // },
          {
            url: '/resources/training',
            name: 'Interview Training Video',
            by: 'Alvigor',
            description: '',
            image: await this.$generatePresignedUrl('media/applicant_training.jpg'),
            id: '3',
          },
        ]
      } else if (
        account_type === this.$AccountTypes.AGENCY || account_type === this.$AccountTypes.EMPLOYER
      ) {
        this.getRecords()
        // this.resources = [
        //   {
        //     url: '/resources/user-guide',
        //     name: 'User Manual',
        //     by: 'HeadHunters HQ',
        //     description: '',
        //     image: await this.$generatePresignedUrl('media/black_hq_logo.png'),
        //     id: '1',
        //   },
        //   {
        //     url: '/resources/training',
        //     name: 'Interview Training Video',
        //     by: 'Alvigor',
        //     description: '',
        //     image: await this.$generatePresignedUrl('media/employer_training.jpg'),
        //     id: '2',
        //   },
        // ]
      }
    },

    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    // this.getData()
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
    },

    onSortChange(params) {
      if (params[0]) {
        const fieldName = params[0].field
        const sortDetails = { }
        sortDetails[fieldName] = params[0].type
        this.updateParams({ sort: sortDetails })
      }
    },

    onColumnFilter(params) {
      this.updateParams(params)
    },
    onSelectionChange(params) {
      this.selectedRows = params.selectedRows
    },
    updateFilters(column, value) {
      const currentFilters = this.serverParams.columnFilters
      currentFilters[column] = value
      this.updateParams({ columnFilters: currentFilters })
    },
  },

}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
